//Signin.js
import React, { useContext } from 'react';
import {firebaseAuth} from '../provider/AuthProvider'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import Home from '../pages/Home';

const AuthWrapper = (props) => {

  const {handleOAuth2Signin, setInputs, checkAuthUser, errors, currentUser} = useContext(firebaseAuth)

  const handleToken = async (token) => {
    await handleOAuth2Signin(token);
    props.history.push('/');
  }

  var queryParams = props.location.search;
  var urlParams = new URLSearchParams(queryParams);

  if (urlParams.has('token') || currentUser !== null) {
    handleToken(urlParams.get('token'))
      .then(() => console.log('Successful sign in via Firebase Token'))
      .catch(err => console.log(err));

    checkAuthUser();
  }

  return <Home/>;
};

export default withRouter(AuthWrapper);
