import 'core-js/stable';
import 'cross-fetch/polyfill';
import React, { useContext, useEffect } from 'react';
import './scss/index.scss';
import {
  BrowserRouter,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import AuthWrapper from './components/AuthWrapper';
import Signin from './components/Signin';
import TopNav from './components/TopNav';
import MapView from './pages/MapView';
import Home from './pages/Home';
import {firebaseAuth} from './provider/AuthProvider'

function App() {

  const { checkAuthUser, handleUserData, currentUser } = useContext(firebaseAuth)

  useEffect(() => {
    checkAuthUser();
    handleUserData();
  }, [currentUser]);

  return (
    <div className="App">
      <BrowserRouter>
        <>
          <TopNav/>
          <Switch>
            <Route exact path='/' render={(props) => currentUser !== null ? <Home /> : <Signin/> } />
            <Route exact path='/auth' render={(props) => <AuthWrapper {...props}/>} />
            <Route exact path='/signin' render={(props) => (currentUser !== null) ? <Home /> : <Signin/> } />
            <Route path='/mapview/:mapSlug' render={(props) => (currentUser !== null) ? <MapView {...props} /> : <Signin /> } />
            <Route path="/static/icons/*" />
          </Switch>
        </>
      </BrowserRouter>
    </div>
  );

}

export default withRouter(App);
