//MapNav.js
import React, { useContext, useState, useEffect} from 'react';
import { firebaseAuth } from '../provider/AuthProvider'
import { withRouter } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { Link } from "react-router-dom";


function MapNav(props) {
  const { handleSignout } = useContext(firebaseAuth)

  const handleSignoutSubmit = async (e) => {
    e.preventDefault()
    await handleSignout()
    props.history.push('/')
  }

  const { currentUserViewMaps } = useContext(firebaseAuth)
  const [ mapListUser, setMapListUser ] = useState(null);

  useEffect(() => {
    setMapListUser(currentUserViewMaps);
  }, [currentUserViewMaps]);

  function sortMapCallback(a, b) {
    var a_comp = a[0].toLowerCase();
    var b_comp = b[0].toLowerCase();

    if (a_comp === b_comp){
        return 0;
    }
    return a_comp > b_comp ? 1 : -1;
  }

  const mapsToView = () => {
    return (mapListUser) ? Object.entries(mapListUser.viewableMaps).filter(mapPerm => mapPerm[1]).sort(sortMapCallback) : [];
  }


  return (
    <Navbar bg="light">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="flex-column">
          <NavItem>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
          </NavItem>
          {
            mapsToView().map(([key, value]) => {
              return (
              <NavItem key={key}>
                <a href={`/mapview/${key}`} className="nav-link" style={{textTransform:"capitalize"}}>{key} Map</a>
              </NavItem>
              )
            })
          }
          <NavItem>
            <Nav.Link onClick={handleSignoutSubmit}>Sign Out</Nav.Link>
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
export default withRouter(MapNav);
