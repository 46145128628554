//MapView.js
import 'core-js/stable'
import 'cross-fetch/polyfill';

import React, { useContext, useState, useEffect } from 'react';
import { firebaseAuth } from '../provider/AuthProvider';
import MapNav from '../components/MapNav';
import Script from 'react-load-script';
import { Container } from 'react-bootstrap';
import MapBoxScript from '../components/mapboxscript';

const MapView = (props) => {

  const { mapSlug } = props.match.params;
  const { currentMap, currentUserViewMaps, handleMapData, currentUser } = useContext(firebaseAuth)
  const [ showToggle, setShowToggle ] = useState(true);
  const [ showMenuToggle, setShowMenuToggle ] = useState(false);
  const [ mapGlLoad, setMapGlLoaded ] = useState(false);
  const [ firebaseAppLoad, setFirebaseAppLoaded ] = useState(false);
  const [ firebaseAuthLoad, setFirebaseAuthLoaded ] = useState(false);
  const [ firebaseDBLoad, setFirebaseDBLoaded ] = useState(false);
  const [ firebaseFireStoreLoad, setFirebaseFireStoreLoaded ] = useState(false);
  const [ canShowMap, setCanShowMap ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {

    const viewedMapIsInApprovedList = () => {
      if(!mapSlug) setIsLoading(false)

      if(currentUserViewMaps && mapSlug) {
        handleMapData(mapSlug);

        if(currentUserViewMaps.viewableMaps[mapSlug]) {
          setCanShowMap(true);
        } else {
          setIsLoading(false);
        }
      }
    }

    viewedMapIsInApprovedList();

  }, [ canShowMap, currentUserViewMaps, mapSlug ]);

  const toggleLegend = () => {
    setShowMenuToggle(false);
    setShowToggle(state => !state);
  }

  const toggleMenu = () => {
    setShowToggle(false);
    setShowMenuToggle(state => !state);
  }

  const handleMapboxScriptLoad = () => {
    setMapGlLoaded(true);
  }

  const handleFirebaseAppLoad = () => {
    setFirebaseAppLoaded(true);
  }

  const handleFirebaseAuthLoad = () => {
    setFirebaseAuthLoaded(true);
  }

  const handleFirebaseDBLoad = () => {
    setFirebaseDBLoaded(true);
  }

  const handleFirebaseFireStoreLoad = () => {
    setFirebaseFireStoreLoaded(true);
  }

  const mapConfiguration = () => {
    return JSON.stringify(currentMap);
  }

  return (

    <>

      <link href='https://api.tiles.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css' rel='stylesheet' />

      <div id="main_container">
        { ( ! canShowMap ) ?
            <Container className="text-center my-5">
              { (isLoading) ? <p>Loading...</p> : <strong>No map found.</strong>}
            </Container>
          :
            <>
              <div id='map' data-map-config={ mapConfiguration() }></div>
              <div className='map_overlay' id='legend' style={{display: showToggle ? 'block' : 'none' }}></div>
              <div id="map_button_group">
                <div id="map-menu-wrapper" style={{display: showMenuToggle ? 'block' : 'none' }}>
                  <MapNav/>
                </div>
                <button id="toggle_button" onClick={toggleLegend}>{showToggle ? 'Hide Legend' : 'Show Legend' }</button>
                <div id="menuToggle" onClick={toggleMenu}>
                  <input type="checkbox" />
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="centered_div_container">
            <div className="centered_div">
              <a id="logo_link" href="https://mysticetus.com" rel="noopener noreferrer" target="_blank">
                <img id="logo" src="/static/mysticetus_logo.png" alt="Mysticetus"/>
              </a>
            </div>
          </div>
            </>
        }
      </div>
      <Script
        url="https://api.tiles.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.js"
        onLoad={() => handleMapboxScriptLoad()}
      />
      <Script
        url="https://www.gstatic.com/firebasejs/7.23.0/firebase-app.js"
        onLoad={() => handleFirebaseAppLoad()}
      />
    	{
        (firebaseAppLoad) ?
        <Script
          url="https://www.gstatic.com/firebasejs/7.23.0/firebase-auth.js"
          onLoad={() => handleFirebaseAuthLoad()}
        />
        : ""
      }
      {
    	  (firebaseAppLoad) ?
        <Script
          url="https://www.gstatic.com/firebasejs/7.23.0/firebase-database.js"
          onLoad={() => handleFirebaseDBLoad()}
        />
        : ""
      }
      {
        (firebaseAppLoad) ?
        <Script
          url="https://www.gstatic.com/firebasejs/7.23.0/firebase-firestore.js"
          onLoad={() => handleFirebaseFireStoreLoad()}
        />
        : ""
      }
      { (mapGlLoad && firebaseAppLoad && firebaseAuthLoad
         && firebaseDBLoad && firebaseFireStoreLoad && canShowMap) ?
        //load this only after mapbox and firebase have loaded.
        < MapBoxScript />
        : "" }
    </>
  );
};

export default MapView;
