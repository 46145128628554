import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import firebaseconfig from './firebaseIndex';

export const authMethods = {
    getuserdata: (setErrors, currentUser, setViewableMapsPerUser) => {
        if (currentUser !== null) {
            firebase.database().ref('clientmaps/users/' + JSON.parse(currentUser).uid ).once('value').then(function(snapshot) {
                return (snapshot.val()) || null;
            }).then(mapdata_users => {
                setViewableMapsPerUser(mapdata_users);
            }).catch(err => {
                //setErrors(prev => ([...prev, err.message]))
            })
        }
    },
    getmapdata: (setErrors, mapname, setCurrentMap) => {
        if (mapname !== null) {
            firebase.database().ref('clientmaps/mapdata/' + mapname).once('value').then(function(snapshot) {
                return (snapshot.val()) || null;
            }).then(mapdata => {
                setCurrentMap(mapdata);
            }).catch(err => {
                setErrors(prev => ([...prev, err.message]))
            })
        }
    },
    signinstatus: (setAuthUser) => {
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                // User is signed in.
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser( window.localStorage.authUser );
                console.log("User is logged in.");
            } else {
                localStorage.removeItem('authUser')
                setAuthUser(null);
                console.log("User is not logged in.");
            }
        });
    },
    signin: (email, password, setErrors, setAuthUser) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function() {
            return firebase.auth().signInWithEmailAndPassword(email, password)
                .then( res => {
                    firebase.auth().onAuthStateChanged(function(user) {
                        if (user) {
                            localStorage.setItem('authUser', JSON.stringify(user));
                            setAuthUser( window.localStorage.authUser );
                        }
                      });
                })
                .catch(err => {
                    console.log(err);
                    setErrors(prev => ([...prev, err.message]))
                })
            })
        .catch(function(error) {
            console.log("setPersistence Error:", error);
        });
    },
    oauth2signin: (token, setErrors, setAuthUser) => {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(function() {
          return firebase.auth().signInWithCustomToken(token)
              .then( res => {
                  firebase.auth().onAuthStateChanged(function(user) {
                      if (user) {
                          localStorage.setItem('authUser', JSON.stringify(user));
                          setAuthUser( window.localStorage.authUser );
                      }
                    });
              })
              .catch(err => {
                  console.log(err);
                  setErrors(prev => ([...prev, err.message]))
              })
          })
      .catch(function(error) {
          console.log("setPersistence Error:", error);
      });
    },
    signout: (setErrors, setAuthUser) => {
        firebase.auth().signOut().then( res => {
            localStorage.removeItem('authUser');
            setAuthUser(null);
        })
        .catch(err => {
            setErrors(prev => ([...prev, err.message]))
                localStorage.removeItem('authUser')
                console.error(err.message)
        })
    },
}
