import React, { useState } from 'react';
import {authMethods} from '../firebase/authmethods'

export const firebaseAuth = React.createContext()

const AuthProvider = (props) => {
    const initState = {email: '', password: ''};
    const [inputs, setInputs] = useState(initState);
    const [errors, setErrors] = useState([]);
    const [authUser, setAuthUser] = useState(null);
    const currentUser = localStorage.getItem('authUser');
    const [currentMap, setCurrentMap] = useState(null);
    const [currentUserViewMaps, setViewableMapsPerUser] = useState(null);

    const handleSignin = () => {
        authMethods.signin(inputs.email, inputs.password, setErrors, setAuthUser);
    }

    const handleOAuth2Signin = (token) => {
        authMethods.oauth2signin(token, setErrors, setAuthUser);
    }

    const handleUserData = () => {
        authMethods.getuserdata( setErrors, currentUser, setViewableMapsPerUser );
    }

    const handleMapData = ( mapname ) => {
        authMethods.getmapdata( setErrors, mapname, setCurrentMap );
    }

    const handleSignout = () => {
        authMethods.signout(setErrors, setAuthUser)
    }

    const checkAuthUser = () => {
        authMethods.signinstatus(setAuthUser)
    }
    return (
        <firebaseAuth.Provider
            value={{
                handleSignin,
                handleOAuth2Signin,
                inputs,
                setInputs,
                errors,
                handleSignout,
                authUser,
                setAuthUser,
                checkAuthUser,
                currentUser,
                handleUserData,
                currentMap,
                currentUserViewMaps,
                handleMapData
        }}>
        {props.children}
        </firebaseAuth.Provider>
  );
};

export default AuthProvider;
