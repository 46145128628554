//Signin.js
import React, { useContext } from 'react';
import {firebaseAuth} from '../provider/AuthProvider'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { withRouter } from "react-router-dom";

const Signin = (props) => {

  const {handleSignin, inputs, setInputs, errors} = useContext(firebaseAuth)

  const handleSubmit = async (e) => {
    e.preventDefault()
    await handleSignin()
    inputs.email = "";
    inputs.password = "";
    props.history.push('/')
  }

  const showProps = () => {
    console.log('props:');
    console.log(props);
  }

  const handleChange = e => {
    const {name, value} = e.target;
    setInputs(prev => ({...prev, [name]: value}));
  }

  return (
    <Container>
      <Row className="m-2">
        <Col sm="12" md="6">
          <h1>Sign In</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control size="sm" type="text" onChange={handleChange}  autoComplete="username" name="email" placeholder='email' value={inputs.email} />
              </Form.Group>
              <Form.Group>
              <Form.Label>Password</Form.Label>
                <Form.Control size="sm" type="password" onChange={handleChange}  autoComplete="current-password" name="password" placeholder='password' value={inputs.password} />
              </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
            <button onClick={() => showProps()}>LogProps</button>
            {errors.length > 0 ? errors.map(error => <p style={{color: 'red'}} key={error} >{error}</p> ) : null}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Signin);
