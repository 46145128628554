import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import React from 'react';
import Script from 'react-load-script';

const MapBoxScript = () => {

    return (
        <Script
        url={window.location.origin +  "/static/mapboxPageScript.js"}
        attributes={{ type: 'module', crossorigin: 'use-credentials' }}
        />
    )
}
export default MapBoxScript;
