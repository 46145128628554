// Home.js
import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {firebaseAuth} from '../provider/AuthProvider'
import { Container } from 'react-bootstrap';

const Home = () => {

  const { currentUserViewMaps } = useContext(firebaseAuth)
  const [ clientName, setClientName ] = useState();

  useEffect(() => {
    if(currentUserViewMaps !== null) {
      setClientName(currentUserViewMaps.clientName);
    }
  }, [currentUserViewMaps]);

  return (
    <Container className="text-center my-5">
      <p>
        { (currentUserViewMaps) ? "Welcome " + clientName: "" }
      </p>
      <a href="https://mysticetus.com" target="_blank" rel="noopener noreferrer">
        <img id="home_logo" src="/static/mysticetus_logo.png" alt="Mysticetus"/>
      </a>
    </Container>
  );
};

export default withRouter(Home);
